import React from 'react';
import { Grid } from 'semantic-ui-react';
import logo from '../static/images/logo-tilia.png';

import { H1, H2, HeaderContainer, LoginContainer } from './ui/index.js';
const logoStyle = {
  height: 150,
  marginBottom: '4em',
  marginTop: '2em',
};

class ConfirmationPremium extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.getTokenPayment.bind(this);
    this.state = {
      status: '',
    };
  }

  componentDidMount() {
    console.log('cancel v3');
    this.premiumConfirm();
  }
  getTokenPayment() {
    console.log(this.props);
    console.log('cancel');
    this.setState({ status: 'success' });
    if (window.webkit != undefined) {
      if (window.webkit.messageHandlers.appInterface != undefined) {
        window.webkit.messageHandlers.appInterface.postMessage('refused');
      }
    }
    if (window.appInterface != undefined) {
      window.appInterface.postMessage('refused');
    }
  }

  premiumConfirm() {
    let API_DOMAIN;
    let X_API_KEY;
    let callUrl = window.location.href;
    let pathParam = window.location.search;
    let res = pathParam.split('&');
    let careUUID = res[0].substring(10, res[0].length);
    let seniorUUID = res[1].substring(11, res[1].length);
    console.log(careUUID);

    console.log(seniorUUID);

    switch (callUrl.substring(0, 11)) {
      case 'https://dev':
        API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
        X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
        break;
      case 'https://ppd':
        API_DOMAIN = 'https://ppd-api.preprod.tilia-aidants.fr';
        X_API_KEY = 'P0zsjgtZ9Q95ZhFCnPVJa5KDk6gJDp4t5o3aMyQY';
        break;
      case 'https://prd':
        API_DOMAIN = 'https://prd-api.tilia-aidants.fr';
        X_API_KEY = 'ILQ6skss0naN6mzebMOTb8SzSPE295oJ6BZtcVKT';
        break;
      default:
        API_DOMAIN = 'https://develop-api.dev.tilia-aidants.fr';
        X_API_KEY = 'qI3r9FV2hra33AkmOfWfu1fxqVaiWVoK85LVcQjy';
    }
    if (this.state.status == '') {
      return fetch(API_DOMAIN + '/me/premium', {
        method: 'PATCH',
        body: JSON.stringify({
          caregiverProfileUUID: careUUID,
          seniorProfileUUID: seniorUUID,
        }),
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': X_API_KEY,
        },
      })
        .then((response) => {
          console.log(response);
          this.setState({ status: 'success' });
          return response.json();
        })
        .then((jsonRes) => {
          console.log(JSON.stringify(jsonRes));
        })
        .catch((err) => {
          alert('une erreur est survenue.');
          return err;
        });
    }
  }

  render() {
    if (this.state.status == '') {
      return null;
    } else
      return (
        <LoginContainer>
          <Grid verticalAlign='middle'>
            <Grid.Column
              style={{
                maxWidth: 450,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}>
              <HeaderContainer>
                <img style={logoStyle} src={logo} alt='Logo' />
                <H1>Tilia</H1>
                <H2>{`Votre compte est confirmé`}</H2>
                <a href='https://tilia-aidants.onelink.me/2rW7' id='link'></a>
              </HeaderContainer>
            </Grid.Column>
          </Grid>
        </LoginContainer>
      );
  }
}
export default ConfirmationPremium;
